import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import llclogo from './images/voidmawlogo.png';
import steamlogo from './images/logos/steam.webp';
import logo from './images/logo.png';
import headerImage from './images/header.jpg';
import video1 from './videos/floorslide.mp4';
import video2 from './videos/floorslide.webm';
import video3 from './videos/hound.mp4';
import video4 from './videos/hound.webm';

function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const scrollToVideo = () => {
    const videoElement = document.querySelector('.header-video-container');
    if (videoElement) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      const videoPosition = videoElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 20; // 20px padding
      window.scrollTo({ top: videoPosition, behavior: 'smooth' });
    }
  };

  const openSteamPage = () => {
    window.open('https://store.steampowered.com/app/3032830?utm_source=site', '_blank');
  };

  const scrollToContent = () => {
    const contentElement = document.querySelector('.App-content');
    if (contentElement) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      const contentPosition = contentElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 20; // 20px padding
      window.scrollTo({ top: contentPosition, behavior: 'smooth' });
    }
  };

  const handleVideoClick = (event) => {
    const videoElement = event.target;
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) { // Firefox
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { // IE/Edge
      videoElement.msRequestFullscreen();
    }
  };

  const handleVideoError = (e) => {
    console.error('Video failed to load', e);
  };

  return (
    <div className="App">
      <Navbar />
      <div className="header-container">
        <img src={headerImage} alt="Header" className="header-image" />
        <div className="logo-and-buttons">
          <img src={logo} alt="Katanaut Logo" className="header-logo" />
          <div className="button-container">
            <button className="wishlist-button" onClick={openSteamPage}>
              WISHLIST
            </button>
            <button className="trailer-button" onClick={scrollToVideo}>
              TRAILER
            </button>
          </div>
          <div className="about-container">
            <button className="about-button" onClick={scrollToContent}>ABOUT</button>
          </div>
          <div className="steam-logo-container" onClick={openSteamPage}>
            <img src={steamlogo} alt="Steam Logo" className="steam-logo" />
            <span className="release-date">2025 - DEMO OUT NOW</span>
          </div>
        </div>
      </div>

      <div className={`header-video-container ${isMobile ? 'mobile-header' : ''}`}>
        <iframe
          className="header-video"
          src="https://www.youtube.com/embed/xGQdrj2H71I"
          title="Katanaut Trailer"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="App-content">
        <h1 className="main-title">What is Katanaut?</h1>
        <div className="description-container">
          <p>
            <b>Katanaut</b> is a action-roguelite set aboard a massive space station falling into cosmic chaos. With the station slowly spiraling out of control, and its once-human inhabitants twisted into terrors, you’re the unlucky one tasked with uncovering what is going on... assuming you’re able to survive the station's violent halls with souls-like combat.
          </p>
          <p>
            As Naut, you explore the corridors of this once vibrant station... or what's left of it. The deeper you descend into the station, the environments become more haunting, and the enemies less human.
          </p>
          <p>
            Through advanced time-manipulation, you’re brought back to life whenever your bio-metrics are no longer detected. In the future where humans are able to download new abilities on demand, the station is now scattered with memory fragments from the fallen. Each one having the potential of being a lost skill or talent waiting to be unlocked.
          </p>
          <p>
            Every run offers a new pivoted challenge with ever-changing pathways to explore, and pits you against hordes of enemies. Each ride down the station's elevators carries forward progress from the memory fragments you scavenge along your exploration.
          </p>
          <p>
            In Katanaut, each clash of your blade echoes through the station's empty and flickering corridors, where every corner feels like is a surprise watching you... Whether you thrive in precision combat or prefer dealing with enemies from a safe distance, this is one cleanup job you won't forget.
          </p>
        </div>

        <div className="feature-grid">
          <p><b style={{ color: '#d0be54' }}>Diverse Builds:</b> Experiment with different weapons, guns, and abilities to create a strategy that matches your style, or something completely unexpected.</p>
          <p><b style={{ color: '#d0be54' }}>Unique Challenges:</b> Experience a unique mixture of hand-crafted and procedurally generated levels where it could be a quick and easy run, or an intense onslaught of surprises.</p>
          <p><b style={{ color: '#d0be54' }}>Unlockable Arsenal:</b> Roguelites aren't roguelites without unlockable items! Katanaut offers a vast number of weapons, skills, and perks waiting to be discovered and upgraded.</p>
          <p><b style={{ color: '#d0be54' }}>High-Stakes Combat:</b> Got quick reflexes? We serve up speedy fights where playing smart and thinking quick can make all the difference.</p>
          <p><b style={{ color: '#d0be54' }}>Pixel-Perfect Design:</b> Experience a pixel-art universe where all the details were refined and designed by hand. All to inspire the unsettling beauty of this cosmic nightmare!</p>
          <p><b style={{ color: '#d0be54' }}>Synthwave:</b> Our soundtrack brings immersive energy to every battle and simultaneously aims to paint the vibe of the station’s darkest corners – all made by one immensely talented musician!</p>
        </div>
        <div className="videos">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video"
            onClick={handleVideoClick}
            onCanPlayThrough={(e) => e.target.play()}
            onError={handleVideoError}
          >
            <source src={video1} type="video/mp4" />
            <source src={video2} type="video/webm" />
          </video>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video"
            onClick={handleVideoClick}
            onCanPlayThrough={(e) => e.target.play()}
            onError={handleVideoError}
          >
            <source src={video3} type="video/mp4" />
            <source src={video4} type="video/webm" />
          </video>
        </div>
      </div>
      <div className="footer-logo-container">
        <img src={llclogo} alt="Voidmaw Logo" className="footer-logo" />
      </div>
    </div>
  );
}

export default Home;
