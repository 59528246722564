import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Home from './Home';
//import PressKit from './PressKit';
import Contact from './Contact';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
          <Helmet>
            <title>Katanaut</title>
            <meta name="description" content="Katanaut is a fast-paced action-roguelite unfolding within the eerie, decaying halls of a massive space station. Following a mysterious catastrophe, the station has become home to relentless enemies, hidden relics, and blood-soaked horrors." />
            <meta property="og:url" content="https://www.katanaut.com" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Katanaut" />
            <meta property="og:description" content="Katanaut is a fast-paced action-roguelite unfolding within the eerie, decaying halls of a massive space station. Following a mysterious catastrophe, the station has become home to relentless enemies, hidden relics, and blood-soaked horrors." />
            <meta property="og:image" content="https://i.imgur.com/QHYTfGa.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="katanaut.com" />
            <meta property="twitter:url" content="https://www.katanaut.com" />
            <meta name="twitter:title" content="Katanaut" />
            <meta name="twitter:description" content="Katanaut is a fast-paced action-roguelite unfolding within the eerie, decaying halls of a massive space station. Following a mysterious catastrophe, the station has become home to relentless enemies, hidden relics, and blood-soaked horrors." />
            <meta name="twitter:image" content="https://i.imgur.com/QHYTfGa.png" />
          </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/press-kit" element={<PressKit />} /> */}
          <Route path="/press-kit" element={<Navigate to="/Press.html" replace />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
